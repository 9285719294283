@font-face {
    font-family: 'coastuserfriendly4regular';
    src: url('/css/custom/Fonts/coastuserfriendly4-webfont.eot');
    src: url('/css/custom/Fonts/coastuserfriendly4-webfont.eot?#iefix') format('embedded-opentype'), url('/css/custom/Fonts/coastuserfriendly4-webfont.woff2') format('woff2'), url('/css/custom/Fonts/coastuserfriendly4-webfont.woff') format('woff'), url('/css/custom/Fonts/coastuserfriendly4-webfont.ttf') format('truetype'), url('/css/custom/Fonts/coastuserfriendly4-webfont.svg#coastuserfriendly4regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: coast-icons;
    src: url(/css/custom/Fonts/coast-icons.eot);
    src: url(/css/custom/Fonts/coast-icons.eot?#iefix) format("embedded-opentype"),url(/css/custom/Fonts/coast-icons.woff) format("woff"),url(/css/custom/Fonts/coast-icons.ttf) format("truetype");
    font-weight: 400;
    font-style: normal;
}


/* Icons */
@font-face {
    font-family: 'slick';
    font-weight: normal;
    font-style: normal;
    src: url('/css/custom/Fonts/slick.eot');
    src: url('/css/custom/Fonts/slick.eot?#iefix') format('embedded-opentype'), url('/css/custom/Fonts/slick.woff') format('woff'), url('/css/custom/Fonts/slick.ttf') format('truetype'), url('/css/custom/Fonts/slick.svg#slick') format('svg');
}